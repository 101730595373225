import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormGroup, ValidationErrors} from "@angular/forms";
import {FormField} from "../../model/formField";
import {finalize, Subscription} from "rxjs";
import {FileService} from "../../services/file.service";
import {SignUp, UploadedDocument} from "../../model/signup";
import {UploadedFileTypeVarName} from "../../model/sentfile";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {KeycloakService} from "keycloak-angular";
import {FileUpload} from "../../model/FileUpload";


@Component({
  selector: 'app-uploader-component',
  templateUrl: './app-uploader.component.html',
  styleUrls: ['./app-uploader.component.css']
})
export class UploaderComponent implements OnInit {

  @Input() showError: ValidationErrors;
  @Input() field!: FormField;
  @Input() form!: FormGroup;
  @Input() acceptedFiles: string;
  @Input() signUp: SignUp;
  @Input() checking: boolean = false;
  @Output() uploadedFile = new EventEmitter<any>();
  @Output() uploadFileInProgress = new EventEmitter<string[]>();

  documentsInProgress: FileUpload[] = [];
  @Input() documentsInProgressName: string[];
  documents: UploadedDocument[];

  uploadProgress:number;
  uploadSub: Subscription;

  accessTokenTTL: number = 299; // < 5 minutes

  ngOnInit() {
    if (this.signUp.additionalInformation?.documents) {
      this.documents = JSON.parse(JSON.stringify(this.signUp.additionalInformation.documents));
    } else {
      this.documents = []
    }
  }

  constructor(private fileService: FileService, protected readonly keycloak: KeycloakService) {}

  public onUpload($event): void {
    if (this.documentsInProgress.length === 0) {
      this.keycloak.updateToken(this.accessTokenTTL).then(r => this.onChange($event));
    } else {
      this.onChange($event)
    }
  }

  public getFiles() {
    return this.documents.filter((item) => UploadedFileTypeVarName[this.field.varName.split('\.')[0]] == item.type);
  }

  onChange(event) {
    const files: File[] = Array.from(event.target.files);
    this.documentsInProgressName.push(...files.map((item) => item.name));
    this.uploadFileInProgress.emit(this.documentsInProgressName);
    this.documentsInProgress = files.map(file => {
      return Object.assign(file, { uploadProgress: 0 });
    });

    files.forEach(file => {
      if (file) {
        event.target.value = '';

        let uploadedDocument = {
          "type": UploadedFileTypeVarName[this.field.varName.split('\.')[0]],
          "fileName": '',
          "originalFileName": file.name,
          "varName": this.field.varName,
          "malwareDetected": false,
          "malwareName": ""
        }

        let formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('uploadedDocument', JSON.stringify(uploadedDocument));

        const upload$ = this.fileService.saveFile(this.signUp.id, formData).pipe(
          finalize(() => this.reset())
        );

        this.uploadSub = upload$.subscribe(event => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * (event.loaded / event.total));
            this.documentsInProgress.find(item => item.name === file.name).uploadProgress = this.uploadProgress;

          }
          if (event instanceof HttpResponse) {
            uploadedDocument.fileName = event.body["fileName"];
            this.uploadedFile.emit(uploadedDocument)
            this.documents.push(uploadedDocument);

            this.documentsInProgress = this.documentsInProgress.filter(item => item.name !== file.name)

            this.documentsInProgressName = this.documentsInProgressName.filter(item => item !== file.name)
            this.uploadFileInProgress.emit(this.documentsInProgressName);
          }
        }, (err) => {
          window.alert("Bestand is niet van een geldig formaat of te groot, gelieve een Word, PDF, Excel, Tekst of afbeelding te uploaden van maximaal 25MB");

          // Remove file from file lists, not visible in frontend as in progress.
          this.documentsInProgress = this.documentsInProgress.filter(item => item.name !== file.name)

          this.documentsInProgressName = this.documentsInProgressName.filter(item => item !== file.name)
          this.uploadFileInProgress.emit(this.documentsInProgressName);
        })
      }
    })
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }

  public onRemove($event, uploadedDocument: UploadedDocument): void {
    $event.preventDefault();

    this.fileService.removeFile(this.signUp.id, uploadedDocument.fileName).subscribe(() => {
      this.documents = this.documents.filter(item => item.fileName !== uploadedDocument.fileName);
      this.uploadedFile.emit(uploadedDocument)
    });
  }
}
